import React from 'react'
import { LandingLayout } from '../newDesign/components/sections'
import Link from 'next/link'
import styled from 'styled-components'
import { Colors, Device, Fonts } from '../newDesign/styles'
import { Button, ContentContainer } from '../newDesign/components/base'

const LaunchSoonPage = () => {
  return (
    <LandingLayout white>
      <Content>
        <Title>That page doesn’t exist!</Title>
        <Container>
          <Video preload="auto" controls={false} muted autoPlay={true} loop>
            <source src="/videos/sad.webm" type="video/webm" />
          </Video>
          <Subtitle>404</Subtitle>
        </Container>

        <ParagpaphXl>
          You can return to Enso
          <Link href="/">
            <a>here.</a>
          </Link>
        </ParagpaphXl>
        <ButtonContainer>
          <Button>Take me back</Button>
        </ButtonContainer>
      </Content>
    </LandingLayout>
  )
}

const Content = styled(ContentContainer)`
  margin: 0 auto;
  display: flex;
  text-align: center;
  flex-direction: column;
  padding: 100px 0 0 0;
  align-items: center;

  & img {
    max-width: 650px;
    width: 100%;
    margin: 35px 0 0 0;
  }

  @media ${Device.mobile} {
    padding: 50px 16px;
  }
`

const Title = styled.h1`
  font-size: 81px;
  line-height: 80px;
  font-weight: 800;
  font-family: ${Fonts.secondary};
  color: ${Colors.Primary[800]};

  @media ${Device.mobile} {
    font-size: 36px;
    line-height: 36px;
    width: 80%;
  }
`

const Subtitle = styled.h1`
  font-size: 360px;
  line-height: 360px;
  font-weight: 800;
  font-family: ${Fonts.secondary};
  color: ${Colors.Primary[800]};

  @media ${Device.mobile} {
    font-size: 180px;
    line-height: 180px;
    margin: 40px 0 0 0;
  }
`

const Video = styled.video`
  width: 320px;
  height: auto;
  object-fit: cover;
  position: absolute;
  top: 72px;
  left: 5%;
  z-index: 1;
  animation: position ease;
  animation-duration: 8s;
  animation-iteration-count: infinite;

  @media ${Device.mobile} {
    top: 72px;
    left: -30px;
    width: 180px;
    animation: mPosition ease-in;
    animation-duration: 8s;
    animation-iteration-count: infinite;
  }

  @keyframes position {
    0% {
      top: 300px;
      left: 5%;
    }

    20% {
      top: 72px;
      left: 5%;
    }

    40% {
      top: 72px;
      left: 5%;
    }

    50% {
      top: 300px;
      left: 5%;
    }
    51% {
      top: 300px;
      left: 61%;
    }

    60% {
      top: 72px;
      left: 61%;
      transform: rotate(-15deg);
    }

    90% {
      top: 72px;
      left: 61%;
      transform: rotate(-15deg);
    }

    95% {
      top: 300px;
      left: 61%;
    }

    100% {
      top: 300px;
      left: 5%;
    }
  }

  @keyframes mPosition {
    0% {
      top: 300px;
      left: -30px;
    }

    20% {
      top: 72px;
      left: -30px;
    }

    40% {
      top: 72px;
      left: -30px;
    }

    50% {
      top: 300px;
      left: -30px;
    }
    51% {
      top: 300px;
      left: 61%;
    }

    60% {
      top: 72px;
      left: 61%;
      transform: rotate(-15deg);
    }

    90% {
      top: 72px;
      left: 61%;
      transform: rotate(-15deg);
    }

    95% {
      top: 300px;
      left: 61%;
    }

    100% {
      top: 300px;
      left: -30px;
    }
  }
`

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 350px;
  overflow: hidden;
  object-fit: cover;

  @media ${Device.mobile} {
    height: 220px;
  }

  & h1 {
    background: #ffffff;
    width: 100%;
    //background: radial-gradient(43.32% 43.32% at 50% 50%,rgba(217,107,255,0.6) 0%,rgba(255,255,255,0) 100%);
    left: 50%;
    transform: translate(-50%, 0);
    position: absolute;
    z-index: 2;
    height: 350px;
    mix-blend-mode: screen;

    @media ${Device.mobile} {
      width: 101%;
    }
  }
`

const ParagpaphXl = styled.p`
  font-size: 24px;
  line-height: 36px;
  margin: 40px 0 0 0;
  color: ${Colors.Primary[800]};

  & a {
    text-decoration: underline;
    color: ${Colors.Primary[800]};
    font-weight: 800;
    margin: 0 0 0 5px;
  }

  @media ${Device.mobile} {
    font-size: 16px;
    line-height: 24px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  margin: 32px 0 600px 0;
  gap: 16px;

  @media ${Device.mobile} {
    flex-direction: column;
    align-items: center;
    margin: 32px 0 150px 0;
  }
`

export default LaunchSoonPage
